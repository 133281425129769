<script setup lang="ts">
import { onDeactivated, onMounted, reactive, ref, watch } from 'vue';
import { Resizable } from '@components/common';
import { ChatInput } from './components';
import { Message } from '../../models';

const messagesElement = ref(null);
const props = defineProps<{
  messages: Message[];
  history?: [];
  isOpen?: boolean;
}>();

const emit = defineEmits(['onOpen', 'onSend']);

const state = reactive({
  isOpen: props.isOpen,
  deleteChatIndex: -1,
  inputText: ''
});

watch(() => props.isOpen, (newValue, oldValue) => {
  console.log('qewwqeqw', newValue)
  state.isOpen = newValue;
});

watch(() => props.messages, (newValue, oldValue) => {
  if (props.messages.length) {
    console.log(123)
    setTimeout(() => {
      scrollTo(false);
    })
  }
});

const listener = (e) => {
  console.log(e)
}

const actions = {
  openChat() {
    state.isOpen = !state.isOpen;
    if (state.isOpen) {
      emit('onOpen');
    }
  },
}

const scrollTo = (smooth = true) => {
  if (messagesElement.value) {
    const el = messagesElement.value as HTMLElement;
    el.scrollTo({ top: el.scrollHeight, behavior: smooth ? 'smooth' : undefined });
  }
}

const onSend = (event) => {
  emit('onSend', {
    text: typeof event === 'string' ? event : '',
    file: typeof event !== 'string' ? event : null,
    scrollTo
  });
}

onMounted(() => {
  
  window.addEventListener('click', listener);
});

onDeactivated(() => {
  window.removeEventListener('click', listener);
});
</script>

<template>
  <div class="chat__open" @click="actions.openChat">

  </div>
  <Resizable
    :class="['chat-wrapper', !state.isOpen && 'closed']"
    draggable-class="chat-header"
  >
    <div class="chat" ref="chat">
      <slot name="header"></slot>
      <div class="chat__messages">
        <div class="chat__messages-wrapper" ref="messagesElement">
          <template v-for="(message, index) in props.messages" :key="index">
            <slot name="message" :message="message"> </slot>
          </template>
        </div>
      </div>
      <ChatInput @onSend="onSend"></ChatInput>
    </div>
  </Resizable>
</template>

<style scoped lang="scss">
.chat-wrapper {
  width: 752px;
  min-width: 560px;
  height: calc(100% - 120px);
  min-height: 150px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 0px 11px -4px #3f3f3f;
  animation: fadeIn;
  animation-duration: .3s;
  animation-timing-function: ease-in-out;
  z-index: 10;

  &.closed {
    opacity: 0;
    display: none;
    bottom: 90px;
  }

  @keyframes fadeIn {
    0% {
      display: none;
      opacity: 0;
    }

    100% {
      display: flex;
      opacity: 1;
    }
  }
}

.chat {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: inherit;

  &__messages {
    height: 100%;
    overflow: hidden;
    position: relative;

    &-wrapper {
      padding: 12px;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }

    &:before {
      content: '';
      flex-grow: 1;
    }
  }

  &__open {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    right: 25px;
    bottom: 25px;
    border-radius: 50%;
    box-shadow: none;
    transition: box-shadow .2s ease-in-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main-color);
    color: var(--text-color-button);
    font-size: 30px;

    &::before {
      content: "...";
      position: relative;
      display: block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      transition: box-shadow .2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  
}
</style>