<script setup lang="ts">
import { reactive, ref } from "vue";

const emit = defineEmits(['onSend']);
const fileInput = ref(null);

const state = reactive<{ text: string, file: File | null }>({
  text: '',
  file: null
});

const onSend = () => {
  if (state.file) {
    emit('onSend', state.file);
    state.file = null;
    state.text = '';
  } else if (state.text) {
    emit('onSend', state.text);
    state.text = '';
  }
  
}
const handleFileUpload = (e) => {
  if (e.target.files?.length) {
    const file = e.target.files[0];
    state.file = file;
    state.text = file.name;
  }
}

const onFileRemove = () => {
  state.file = null;
  state.text = '';
  if (fileInput?.value) {
    (fileInput.value as HTMLInputElement).value = '';
  }
}
</script>

<template>
  <div class="chat-input">
    <div class="chat-input__wrapper">
      <label for="file" class="chat-input__attach">
        <input type="file" hidden id="file" ref="fileInput" @change="handleFileUpload" />
        <icon name="attachment"></icon>
      </label>
      <input v-if="!state.file" class="chat-input__input" v-model="state.text" placeholder="Message Cartie" type="text" @keydown.enter="onSend" />
      <div v-else class="chat-input__attachment">
        <span>{{ state.file.name }}</span>
        <icon name="x" class="chat-input__attachment-close" @click="onFileRemove"></icon>
      </div>
      <div>
        <icon class="chat-input__voice" name="voice"></icon>
        <icon class="chat-input__send" name="send" @click="onSend"></icon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@styles/mixins.scss";

.chat-input {
  padding: 8px;

  &__wrapper {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: #F7F7F7;
    border-radius: 24px;
    line-height: 12px;
  }

  &__input {
    border: none;
    background-color: #F7F7F7;
    flex-grow: 1;
    padding: 8px;
    font-size: 16px;

    &:focus-visible {
      outline: none;
    }
  }

  &__voice {
    margin-right: 8px;
    cursor: pointer;
  }

  &__send {
    color: var(--main-color);
    cursor: pointer;
  }

  &__attach {
    cursor: pointer;
  }

  &__attachment {
    @include flex;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 8px;
    line-height: 19px;

    &-close {
      margin-left: 8px;
      cursor: pointer;
    }
  }
}
</style>