import { Message } from "../models";
import { MessageDTO } from "../services/chat.service";

export const getMessageFromMessageGTO = (messages: MessageDTO[]): Message[] => {
  return messages.map(message => {
    const message_ = {
      id: message.id,
      type: message.type === 'html' ? 'text' : message.type,
      data: { ...message.data, text: message.text || '' },
      author: message.role === 'user' ? 'me' : 'support',      
    } as Message;
    if (message.cart) {
      message_.chatName = message.chatName;
      message_.cartName = message.cartName;
    }
    return message_;
  });
}

export const log = (text: string) => {
  console.debug(text);
}

export const fileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = reject;
});