import { apiService } from "./api.service";

export interface RequestDTO {
  method: 'post' | 'get';
  url: string;
  body?: any;
  showSpinner?: boolean;
  spinnerMessage?: string;
  headers?: Record<string, string>;
}

export interface MessageDTO {
  id: string;
  role: 'agent' | 'user';
  type: 'text' | 'api_request' | 'html' | 'local_storage_request' | 'redirect' | 'window_request';
  displayType: 'show' | 'refresh';
  dateCreated: string;
  text: string;
  cart: boolean;
  cartName: string;
  chatName: string;
  data: {
    text?: string;
    key?: string;
    url?: string;
    template?: string;
    prompt?: string;
    props?: any;
    requests?: RequestDTO[];
  }
}

export interface ChatDTO {
  id: string;
  name: string;
  messages: MessageDTO[];
  dateCreated: string;
  dateModified: string;
  userId: string;
}

export interface MessageSent {
  type: 'text' | 'file' | 'api_response' | 'local_storage_response' | 'redirect_response' | 'window_response';
  text?: string;
  data?: {
    [key: string]: any;
  };
}

class ChatService {
  private userId;
  private chatId;

  private url = process.env.API_URL;

  init(userId: string, chatId: string) {
    this.userId = userId;
    this.chatId = chatId;
  }

  getChat(id: string): Promise<ChatDTO> {
    return apiService.get(`${this.url}/api/chats/${id}`);
  }

  getChats(): Promise<ChatDTO[]> {
    return apiService.get(`${this.url}/api/chats`);
  }

  async createChat(chatId?: string): Promise<ChatDTO> {
    const chatDTO = await apiService.post(`${this.url}/api/chats${chatId ? '/' + chatId : ''}`, {});
    return chatDTO;
  }

  deleteChat(chatId: string): Promise<void> {
    return apiService.delete(`${this.url}/api/chats/${chatId}`);
  }

  async deleteItem(chatId: string, messageId: string, itemId: string) {
    return apiService.delete(`${this.url}/api/chats/${chatId}/messages/${messageId}/items/${itemId}`, {});
  }

  async replaceItem(chatId: string, messageId: string, itemId: string, newItemId: string) {
    return apiService.put(`${this.url}/api/chats/${chatId}/messages/${messageId}/items/${itemId}`, { newItemId });
  }

  async sendMessage(payload: MessageSent): Promise<MessageDTO> {
    const chatId = this.chatId;
    const body = {
      type: payload.type,
      text: payload.text,
    } as { type; text; data; }

    if (payload.data) {
      body.data = payload.data;
    }
    return await apiService.post(`${this.url}/api/chats/${chatId}/messages`, body);
  }

  async actions(requests: RequestDTO[], cb = (loadingText: string) => {}): Promise<any[]> {
    const responses: any[] = [];
    for (let request of requests) {
      const data = {
        method: request.method,
        headers: {}
      } as any;
      if (request.body) {
        data.headers = {
          "Content-Type": "application/json"
        }
        data.body = JSON.stringify(request.body);
      }
      if (request.headers) {
        data.headers = {
          ...data.headers,
          ...request.headers
        };
      }
      if (request.showSpinner) {
        cb(request.spinnerMessage || 'loading...');
      }
      try {
        const resp = await fetch(request.url, data);
        responses.push(await resp.text());
      } catch (e) {
        responses.push(e);
      }
    }
    return responses;
  }
}

export const chatService = new ChatService();