<script setup lang="ts">
import { onMounted, ref } from 'vue';

const props = defineProps<{
  draggableClass: string;
}>();

const element = ref(null);
const resizers = ref(null);


let dragEl;
let dragHandleEl
const lastPosition = {};

// setupDraggable();

function setupDraggable(){
  dragHandleEl = document.querySelector(`.${props.draggableClass}`);
  dragHandleEl.addEventListener('mousedown', dragStart);
  dragHandleEl.addEventListener('mouseup', dragEnd);
  dragHandleEl.addEventListener('mouseout', dragEnd);
}

// function setupResizable(){
//   const resizeEl = document.querySelector('[data-resizable]');
//   if (resizeEl) {
//     // @ts-ignore
//     resizeEl.style.setProperty('resize', 'both');
//     // @ts-ignore
//     resizeEl.style.setProperty('overflow','hidden');
//   }
// }

function dragStart(event){
  dragEl = getDraggableAncestor();
  if (dragEl) {
    // dragEl.style.setProperty('position','absolute');
    // @ts-ignore
    lastPosition.left = event.target.clientX;
    // @ts-ignore
    lastPosition.top = event.target.clientY;
    dragHandleEl.classList.add('dragging');
    dragHandleEl.addEventListener('mousemove', dragMove);
  }
}

function dragMove(event){
  const dragElRect = dragEl.getBoundingClientRect();
  // @ts-ignore
  const newLeft = dragElRect.left + event.clientX - lastPosition.left;
  // @ts-ignore
  const newTop = dragElRect.top + event.clientY - lastPosition.top;

  console.log(222, dragEl)
  dragEl.style.setProperty('left', `${newLeft}px`);
  dragEl.style.setProperty('top', `${newTop}px`);
  // @ts-ignore
  lastPosition.left = event.clientX;
  // @ts-ignore
  lastPosition.top = event.clientY;
  // @ts-ignore
  window.getSelection().removeAllRanges();
}

function getDraggableAncestor(){
  if (element.value) {
    const dragEl = element.value;
    if (dragEl) {
      return dragEl;
    }
  }
}

function dragEnd(){
  console.log('lll')
  dragHandleEl.classList.remove('dragging');
  dragHandleEl.removeEventListener('mousemove',dragMove);
  dragEl = null;
}

function makeResizableDiv(element: HTMLElement, resizers_: HTMLElement) {
  // const element = document.querySelector(div);
  
  const resizers = resizers_.children;//document.querySelectorAll(div + ' .resizer')
  const minimum_size = 20;
  let original_width = 0;
  let original_height = 0;
  let original_x = 0;
  let original_y = 0;
  let original_mouse_x = 0;
  let original_mouse_y = 0;
  for (let i = 0;i < resizers.length; i++) {
    const currentResizer = resizers[i];
    currentResizer.addEventListener('mousedown', function(e) {
      e.preventDefault()
      original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
      original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
      original_x = element.getBoundingClientRect().left;
      original_y = element.getBoundingClientRect().top;
      // @ts-ignore
      original_mouse_x = e.pageX;
      // @ts-ignore
      original_mouse_y = e.pageY;
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResize)
    })
    
    function resize(e) {
      if (currentResizer.className.includes('bottom-right')) {
        const width = original_width + (e.pageX - original_mouse_x);
        const height = original_height + (e.pageY - original_mouse_y)
        if (width > minimum_size) {
          element.style.width = width + 'px'
        }
        if (height > minimum_size) {
          element.style.height = height + 'px'
        }
        element.style.top = original_y + 'px';
        element.style.left = original_x + 'px';
      }
      else if (currentResizer.className.includes('bottom-left')) {
        const height = original_height + (e.pageY - original_mouse_y)
        const width = original_width - (e.pageX - original_mouse_x)
        if (height > minimum_size) {
          element.style.height = height + 'px'
        }
        if (width > minimum_size) {
          element.style.width = width + 'px'
          element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
        }
        element.style.top = original_y + 'px';
      }
      else if (currentResizer.className.includes('top-right')) {
        const width = original_width + (e.pageX - original_mouse_x)
        const height = original_height - (e.pageY - original_mouse_y)
        if (width > minimum_size) {
          element.style.width = width + 'px'
        }
        if (height > minimum_size) {
          element.style.height = height + 'px'
          element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
        }
        element.style.left = original_x + 'px';
      }
      else {
        const width = original_width - (e.pageX - original_mouse_x)
        const height = original_height - (e.pageY - original_mouse_y)
        if (width > minimum_size) {
          element.style.width = width + 'px'
          element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
        }
        if (height > minimum_size) {
          element.style.height = height + 'px'
          element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
        }
      }
    }
    
    function stopResize() {
      window.removeEventListener('mousemove', resize)
    }
  }
}

onMounted(() => {
  
  // window.addEventListener('click', listener);
  console.log(element.value)
  // setupResizable();

  // const resizeEl = document.querySelector('[data-resizable]');
  if (element.value && resizers.value) {
    makeResizableDiv(element.value, resizers.value);
    setupDraggable()
    // @ts-ignore
    // element.value.style.setProperty('resize', 'both');
    // @ts-ignore
    // element.value.style.setProperty('overflow','hidden');
  }
});

</script>

<template>
  <div class="element" ref="element">
    <div class='resizers' ref="resizers">
      <div class='resizers__resizer resizers__top-left'></div>
      <div class='resizers__resizer resizers__top-right'></div>
      <div class='resizers__resizer resizers__bottom-left'></div>
      <div class='resizers__resizer resizers__bottom-right'></div>
    </div>
    <slot></slot>
  </div>
</template>


<style scoped lang="scss">
.element {
  background-color: #ccc;
  // width: 100px;
  // height: 100px;
  // position: absolute;
  // top: 100px;
  // left: 100px;
}
.resizers {
  position: relative;
  height: 100%;

  &__resizer {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: inherit;
    // background-color: red;
  }

  $gap: 2px;

  &__top-left {
    left: #{$gap};
    top: #{$gap};
    cursor: nwse-resize;
  }
  &__top-right {
    right: #{$gap};
    top: #{$gap};
    cursor: nesw-resize;
  }
  &__bottom-left {
    left: #{$gap};
    bottom: #{$gap};
    cursor: nesw-resize;
  }
  &__bottom-right {
    right: #{$gap};
    bottom: #{$gap};
    cursor: nwse-resize;
  }
}
</style>
