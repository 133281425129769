<script setup lang="ts">
import { chatService } from '../../services';
import { CartItemComp } from '.';
import { CartItem, CartList, Item } from './cart-list';
import { store } from '../../models';
import { Modal, Button } from '@components/common';
import { ref } from 'vue';

const props = defineProps<{ items: CartItem[], messageId: string }>();
const emit = defineEmits(['add']);
const modalListRef = ref(null);

const state = new CartList();
state.items = props.items;

// const calculateTotal = () => {
//   state.total = Math.ceil(state.items.reduce((sum, item) => (sum += item.selectedItem.price, sum), 0) * 100) / 100;
// }
// calculateTotal();

const onDelete = async (index: number ) => {
  const chatId = store.chats[store.activeChatIndex].id;
  const messageId = props.messageId;
  const itemId = state.items[index].selectedItem.id;
  const message = await chatService.deleteItem(chatId, messageId, itemId);
  store.replaceMessage(message);
}
const onSelectAlternative = async (altIndex: number, index: number) => {
  const chatId = store.chats[store.activeChatIndex].id;
  const messageId = props.messageId;
  const itemId = state.items[index].selectedItem.id;
  const newItemId = state.items[index].alternatives[altIndex].id;
  const message = await chatService.replaceItem(chatId, messageId, itemId, newItemId);
  store.replaceMessage(message);
}
const onReplace = (index: number) => {
  state.selectedItemIndex = index;
  state.isReplaceModalOpen = true;
}
const onCloseModal = () => {
  state.isReplaceModalOpen = false;
  state.selectedItemIndex = -1;
}
const onDetails = (index: number) => {
  console.log(index)
}

const CARD_SCROLL_GAP = 174 * 2;
const onPrev = () => {
  if (modalListRef?.value) {
    const el = (modalListRef.value as HTMLElement);
    el.scrollBy({ left: - CARD_SCROLL_GAP, behavior: 'smooth' });
  }
}
const onNext = () => {
  if (modalListRef?.value) {
    const el = (modalListRef.value as HTMLElement);
    el.scrollBy({ left: CARD_SCROLL_GAP, behavior: 'smooth' });
  }
}
</script>

<template>
  <div class="fc-cart-list">
    <CartItemComp
      v-for="(item, index) in state.items" :key="index"
      class="fc-cart-list__item"
      :item="item.selectedItem"
      :replace="!!item.alternatives.length"
      :remove="true"
      :add="true"
      @remove="onDelete(index)"
      @replace="onReplace(index)"
      @select="onDetails(index)"
    ></CartItemComp>
  </div>

  <Modal
    v-if="state.isReplaceModalOpen && state.selectedItemIndex > -1"
    teleport-to=".chat__messages-wrapper"
    class="fc-cart-list__modal"
    width="354px"
    top="calc(50% - 288px)"
    left="calc(50% - 200px)"
  >
    <template #header>
      <div class="fc-cart-list__modal-header">
        <span>Replace with similar</span>
        <div class="fc-cart-list__modal-close" @click="onCloseModal">
          <Icon name="x"></Icon>
        </div>
      </div>
    </template>
    <div class="fc-cart-list__modal-body">
      <div class="fc-cart-list__modal-list" ref="modalListRef">
        <CartItemComp
          v-for="(item, index) in state.items[state.selectedItemIndex].alternatives" :key="index"
          class="fc-cart-list__modal-item"
          :item="item"
          :select="true"
          @delete="onDelete(index)"
          @replace="onReplace(index)"
          @select="onSelectAlternative(index, state.selectedItemIndex)"
        ></CartItemComp>
      </div>
    </div>
    <template #footer>
      <div class="fc-cart-list__modal-footer">
        <Button hover class="fc-cart-list__modal-button" @click="onPrev">
          <Icon name="chevron" class="fc-cart-list__modal-prev" />
          <span>Prev</span>
        </button>
        <Button hover class="fc-cart-list__modal-button" @click="onNext">
          <span>Next</span>
          <Icon name="chevron" class="fc-cart-list__modal-next" />
        </button>
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
  @import '@styles/mixins.scss';

  .fc-cart-list {
    @include flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 0;
    max-height: 458px;
    overflow-y: auto;

    &__item {
      margin: 0 4px 4px 0;
    }
    
    &__add {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
      cursor: pointer;
      color: green;
      opacity: 0.5;
      transition: 300ms opacity;
      padding-left: 10px;

      &:hover {
        opacity: 1;
      }

      svg {
        cursor: pointer;
      }
    }

    &__total {
      float: right;
      padding-right: 20px;
    }

    &__modal {
      top: 10px;

      &-close {
        cursor: pointer;
        background-color: #F7F7F7;
        padding: 4px;
        border-radius: 8px;
        margin-left: 4px;
        line-height: 16px;
        height: 24px;
      }

      &-list {
        @include flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 456px;
        padding: 4px 2px;
        overflow: hidden;
      }

      &-item {
        cursor: pointer;
        margin: 2px;
      }

      &-header {
        @include flex;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 700;
        border-bottom: 1px solid #DFDFDF;
        padding: 16px;
      }
      
      &-body {
        background: var(--message-background);
        border-radius: 10px;
      }

      &-footer {
        border-top: 1px solid #DFDFDF;
        @include flex;
        justify-content: center;
        padding: 8px;
      }

      &-button {
        @include flex;
        justify-content: center;
        width: 100px;
      }

      &-prev {
        rotate: 90deg;
      }

      &-next {
        rotate: -90deg;
      }
    }
  }
</style>