<script setup lang="ts">
import { store } from '../../../models';
import { chatService } from '@services/index';
import { reactive } from 'vue';
import { Dropdown, Modal, Button } from '@components/common';

const emit = defineEmits(['onCreate', 'onClose', 'onCart', 'onCheckout']);
const props = defineProps<{ cartCount: number; }>();

const state = reactive({
  activeChatIndex: 0,
  deleteChatIndex: -1,
  confirmModalIsOpen: false
});

const onDelete = async (e, proceed = false) => {
  if (proceed) {
    const chatId = store.chats[e].id;
    await chatService.deleteChat(chatId);
    store.deleteMessage(chatId);
    if (state.activeChatIndex === e) {
      onSelectChat(state.activeChatIndex++);
    }
    state.confirmModalIsOpen = false;
    state.deleteChatIndex = -1;
  } else {
    state.confirmModalIsOpen = true;
    state.deleteChatIndex = e;
  }
}

const onSelectChat = (index: number) => {
  if (state.activeChatIndex !== index) {
    const chat = store.chats[index];
    emit('onCreate', chat.id);
  }
  state.activeChatIndex = index;
}
const onCart = (e) => {
  e.stopPropagation();
  emit('onCart');
}
</script>

<template>
  <div class="chat-header">
    <div class="chat-header__wrapper">
      <Dropdown class="chat-header__dropdown">
        <template #header>
          <div class="chat-header__dropdown-header" v-if="store.chats.length">
            <span class="chat-header__dropdown-selected" :title="store.chats[state.activeChatIndex].name">{{ store.chats[state.activeChatIndex].name }}</span>
            <Icon name="chevron"></Icon>
          </div>
        </template>
        <div class="chat-header__dropdown-chats" v-if="store.chats.length">
          <div class="chat-header__dropdown-chats-item" v-for="(item, index) in store.chats" :key="index" @click="onSelectChat(index)">
            <span :title="item.name">{{ item.name }}</span>
            <Icon class="chat-header__dropdown-chats-delete" name="x" @click.stop="onDelete(index)"></Icon>
          </div>
          <Modal
            v-if="state.confirmModalIsOpen && state.deleteChatIndex > -1"
            teleport-to=".chat__messages-wrapper"
            width="354px"
            top="calc(50% - 70px)"
            left="calc(50% - 200px)"
            >
            <div class="chat-header__modal-body">
              Are you sure you want to delete {{ store.chats[state.deleteChatIndex].name }} chat ?
            </div>
            <template #footer>
              <div class="chat-header__modal-footer">
                <Button class="chat-header__modal-cancel" secondary @click="state.confirmModalIsOpen = false; state.deleteChatIndex - 1">cancel</button>
                <Button primary @click="state.confirmModalIsOpen = false; onDelete(state.deleteChatIndex, true)">delete</button>
              </div>
            </template>
          </Modal>
        </div>
      </Dropdown>
      <Icon class="chat-header__create" name="edit" @click="emit('onCreate')"></Icon>
    </div>
    <div class="chat-header__checkout">
      <div class="chat-header__checkout-cart">
        <Icon name="cart" @click="onCart($event)" />
        <div class="chat-header__checkout-cart-count">{{ props.cartCount }}</div>
      </div>
      <Button primary class="chat-header__checkout-button" @click="emit('onCheckout')">Checkout</Button>
      <Icon class="chat-header__checkout-close" name="xx" @click="emit('onClose')"></Icon>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@styles/mixins.scss";

.chat-header {
  @include flex;
  justify-content: space-between;
  padding: 12px;
  color: white;
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid #DFDFDF;
  color: var(--text-color);

  &__create {
    cursor: pointer;
    color: var(--text-color);
  }

  &__wrapper {
    @include flex;
  }

  &__modal {
    &-body {
      padding: 20px;
    }

    &-footer {
      @include flex;
      justify-content: flex-end;
      padding: 20px;
    }

    &-cancel {
      margin-right: 8px;
    }
  }

  &__checkout {
    @include flex;

    &-cart {
      position: relative;
    }

    &-cart svg {
      padding: 4px;
      margin-right: 16px;
      cursor: pointer;
      height: 30px;
      width: 30px;
      
    }

    &-cart-count {
      position: absolute;
      height: 16px;
      width: 16px;
      border-radius: 16px;
      background: #ccc;
      font-size: 9px;
      font-weight: 600;
      color: white;
      @include flex;
      justify-content: center;
      top: -4px;
      right: 13px;
      color: var(--surface);
      background: var(--main-color);
    }

    &-button {
      margin-right: 12px;
    }

    &-close {
      cursor: pointer;
      padding: 8px;
    }
  }

  &__dropdown {
    margin-right: 8px;

    &-header {
      padding: 8px 16px;
      border-radius: 24px;
      background: #F7F7F7;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
    }

    &-selected {
      @include text-ellipsis;
      width: 244px;
    }

    &-chats {
      background: white;
      border-radius: 20px;
      box-shadow: 0px 0px 8px 0px #00000026;
      max-height: 500px;
      overflow: auto;
      padding: 12px;
      margin-top: 6px;
      $this: &;

      &-item {
        @include flex;
        justify-content: space-between;
        background: #fff;
        padding: 8px 12px 8px 12px;
        cursor: pointer;
        border-radius: 20px;

        > span {
          @include text-ellipsis;
          width: 100%;
        }

        &:hover {
          background: var(--main-color-light);

          #{$this}-delete {
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      &-delete {
        margin-left: 8px;
        opacity: 0;
        cursor: pointer;
        transition: 100ms opacity;
      }
    }
  }
}
</style>