<script setup lang="ts">
import { reactive } from 'vue';
import { store } from '../../models';

const state = reactive<{ isOpen: boolean }>({ isOpen: false });
store.subscribe(() => {
  if (state.isOpen) {
    // TODO
  }
});

const onOpen = () => {
  state.isOpen = !state.isOpen;
}
</script>

<template>
  <div class="fc-dropdown">
    <div class="fc-dropdown__header" @click="onOpen">
      <slot name="header"></slot>
    </div>
    <div :class="['fc-dropdown__content', 'fc-dropdown__content' + (state.isOpen ? '--opened' : '')]" ref="el"  @click.stop="onOpen">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.fc-dropdown {
  position: relative;

  &__content {
    width: 100%;
    opacity: 0;
    position: absolute;
    z-index: -1;
    transition: 300ms opacity;

    &--opened {
      opacity: 1;
      z-index: 2;
    }
  }
}
</style>
