<script setup lang="ts">
import { reactive } from 'vue';
import { Item } from '../cart-list';

const { item, replace, remove, add } = defineProps<{
  item: Item;
  replace?: boolean;
  remove?: boolean;
  add?: boolean;
  select?: boolean;
}>();
const emit = defineEmits(['remove', 'replace', 'select']);
const state = reactive({
  quantity: item.quantity || 1,
});

const onPlus = (e: PointerEvent) => {
  e.stopPropagation();
  state.quantity++;
}
const onMinus = (e: PointerEvent) => {
  e.stopPropagation();
  if (state.quantity > 1) {
    state.quantity--;
  }
}
</script>

<template>
  <div class="fc-cart-item" @click="emit('select')">
    <div class="fc-cart-item__wrapper">
      <div class="fc-cart-item__header">
        <div v-if="replace" class="fc-cart-item__icon" @click="emit('replace')">
          Replace
        </div>
        <div v-if="remove" class="fc-cart-item__icon fc-cart-item__close" @click="emit('remove')">
          <Icon name="x"></Icon>
        </div>
      </div>
    
      <div class="fc-cart-item__image">
        <img class="fc-cart-item__img" :src="item.imageUrl" />
      </div>
      <div v-if="select" class="fc-cart-item__select">
        <div class="fc-cart-item__icon">Select</div>
      </div>
      <div v-if="add" class="fc-cart-item__add-remove">
        <div class="fc-cart-item__add-remove-wrapper">
          <icon class="fc-cart-item__add-remove-minus" name="minus" @click="onMinus" />
          {{ state.quantity }}
          <icon class="fc-cart-item__add-remove-plus" name="plus" @click="onPlus" />
        </div>
      </div>
      <div class="fc-cart-item__price">
        <span class="fc-cart-item__price-total">${{ item.price }}</span>
        <span class="fc-cart-item__price-each">{{ item.measurementUnit || 'each' }}</span>
      </div>
      <div class="fc-cart-item__name">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @import '@styles/mixins.scss';

  .fc-cart-item {
    $this: &;
    width: 170px;
    user-select: none;

    &__icon {
      cursor: pointer;
      background-color: #F7F7F7;
      padding: 4px;
      border-radius: 8px;
      margin-left: 4px;
      line-height: 16px;
      height: 24px;
    }

    &__close {
      margin-left: auto;
    }

    &__header {
      @include flex;
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      width: 100%;
      justify-content: space-between;
      padding: 0 8px;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &__select {
      @include flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      top: 0;
      bottom: 0;
      opacity: 0;
    }

    &__add-remove {
      font-size: 12px;
      font-weight: 600;
      padding: 0 8px;
      position: absolute;
      top: 115px;
      width: 100%;
      opacity: 0;
      transition: opacity 0.3s;
      @include flex;
      justify-content: center;

      &-wrapper {
        border: 1px solid #DFDFDF;
        padding: 8px;
        width: 60%;
        border-radius: 24px;
        @include flex;
        justify-content: space-between;
        background-color: #fff;
      }

      &-plus,
      &-minus {
        cursor: pointer;
      }
    }

    &:hover {
      #{$this}__header,
      #{$this}__select,
      #{$this}__add-remove {
        opacity: 1;
      }
      #{$this}__wrapper {
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      }
    }

    &.closed {
      opacity: 0;
      display: none;
      bottom: 90px;
    }

    &__wrapper {
      @include flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      background: #fff;
      border: 1px solid #cdcdcd;
      border-radius: 10px;
      width: 100%;
      height: 220px;
      padding: 10px;
      box-shadow: 0;
      transition: box-shadow 0.3s;
    }

    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &__image {
      @include flex;
      justify-content: center;
      height: 145px;
      width: 100%;
    }

    &__img{
      height: 120px;
      max-width: 160px;
    }

    &__name {
      width: 100%;
      padding: 0 4px;
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      width: 100%;
      padding: 0 4px;
      
      &-total {
        margin-right: 4px;
        font-weight: 700;
      }
      &-each {
        font-size: 12px;
        color: #8A8A8A;
      }
    }

    &__delete {
      cursor: pointer;
      color: red;
      opacity: 0.5;
      transition: 300ms opacity;
      padding-left: 10px;

      &:hover {
        opacity: 1;
      }
    }

    &__alternative {
      width: 300px;
      margin-left: -300px;
      box-shadow: 0px 0px 11px -4px #3f3f3f;
      background: #fff;
      border-radius: 10px;

      &-open {
        cursor: pointer;
      }

      &-img {
        width: 60px;
        margin-right: 10px;
      }

      &-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        background: #fff;
        width: 300px;
        padding: 10px;
        cursor: pointer;

        &:first-child {
          border-radius: 10px 10px 0 0;
        }

        &:last-child {
          border-radius: 0 0 10px 10px;
        }

        &:hover {
          background: #ccc;
        }
      }

      &-name {
        margin-right: 5px;
      }
    }
  }
</style>
