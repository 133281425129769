<script setup lang="ts">

interface Props {
  primary?: boolean;
  secondary?: boolean;
  text?: boolean;
  hover?: boolean;
}

const props = defineProps<Props>();

const getClass = () => {
  if (props.primary) {
    return 'button__primary';
  }
  if (props.secondary) {
    return 'button__secondary';
  }
  if (props.text) {
    return 'button__text';
  }
  if (props.hover) {
    return 'button__hover';
  }
}
</script>

<template>
  <div class="button" :class="getClass()">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.button {
  padding: 8px 16px;
  cursor: pointer;
  border: 0;
  border-radius: 12px;
  text-align: center;
  border: 1px solid;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &:hover {
    opacity: 0.9;
  }

  &__primary {
    background-color: var(--main-color);
    color: var(--surface);
    border-color: var(--main-color);
  }

  &__secondary {
    background-color: var(--surface);
    color: var(--main-color);
    border-color: var(--main-color);
  }

  &__text {
    background-color: transparent;
    color: var(--text-color);
    border: none;
  }

  &__hover {
    color: var(--text-color);
    border-color: transparent;

    &:hover {
      color: var(--surface);
      border-color: var(--main-color);
      background-color: var(--main-color);
      opacity: 1;
    }
  }
}
</style>