import { reactive } from "vue";
import { log } from "../../utils/tools";

export interface Item {
  id: string;
  imageUrl: string;
  name: string;
  price: number;
  quantity: number;
  measurementUnit: string;
}

export interface CartItem {
  alternatives: Item[];
  selectedItem: Item,
}

interface CartListState {
  items: CartItem[];
  total: number;
  isReplaceModalOpen: boolean;
  selectedItemIndex: number;
}

export class CartList {
  private state = reactive<CartListState>({
    items: [],
    total: 0,
    isReplaceModalOpen: false,
    selectedItemIndex: -1
  });

  get items() {
    return this.state.items;
  }
  set items(value: CartItem[]) {
    log('items changed');
    this.state.items = value;
  }

  get total() {
    return this.state.total;
  }
  set total(value: number) {
    log('total changed');
    this.state.total = value;
  }

  get isReplaceModalOpen() {
    return this.state.isReplaceModalOpen;
  }
  set isReplaceModalOpen(value: boolean) {
    log('isReplaceModalOpen changed');
    this.state.isReplaceModalOpen = value;
  }

  get selectedItemIndex() {
    return this.state.selectedItemIndex;
  }
  set selectedItemIndex(value: number) {
    log('selectedItemIndex changed');
    this.state.selectedItemIndex = value;
  }
}