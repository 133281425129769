<script setup lang="ts">
import { chatService } from "./services";
import { fileToBase64, getMessageFromMessageGTO } from "./utils/tools";
import { Compile } from '@components/common';
import { store } from "./models";
import { onDeactivated, onMounted, reactive } from "vue";
import { Chat, ChatHeader, ChatMessage } from '@components/chat';
import { Modal, Button } from '@components/common';
import { Item } from "@components/cart-list/cart-list";
import { CartItemComp } from "@components/cart-list";

interface State {
  confirmModalIsOpen: boolean;
  deleteChatIndex: number,
  inputText: string,
  isCartModalOpen: boolean,
  carts: Cart[];
  count: number;
  total: number;
}

interface Cart {
  items: Item[];
  name: string;
  total: number;
}

const state = reactive<State>({
  confirmModalIsOpen: false,
  deleteChatIndex: -1,
  inputText: '',
  isCartModalOpen: false,
  carts: [],
  count: 0,
  total: 0
});

const onOpen = async () => {
  store.isOpen = true;
  store.chats = await chatService.getChats();
  if (store.chats?.length) {
    const chatId = store.chats[store.activeChatIndex].id;
    createChat(chatId);
  }
}

const createChat = async (id?: string) => {
  let chatDTO;
  if (id) {
    chatDTO = await chatService.getChat(id);
  }
  if (!chatDTO) {
    chatDTO = await chatService.createChat();
    store.chats.unshift(chatDTO);
    const date = new Date();
    store.chats[0].name = `${date.toLocaleDateString("en-US")}, ${date.toLocaleTimeString("en-US")}`;
  }
  store.messages = getMessageFromMessageGTO(chatDTO.messages);
  chatService.init(chatDTO.userId, chatDTO.id);
  createCart();
}

const onClose = () => {
  store.isOpen = false;
} 

const onMessageSent = ({ text, file, scrollTo = () => {} }: { text?: string, file?: File, scrollTo?: () => void }) => {
  if (text || file) {
    if (text) {
      store.message(text);
    }
    if (file) {
      store.message(file.name);
    }
    setTimeout(async () => {
      scrollTo();
      let promise;
      store.asyncMessage();
      if (file) {
        const data = await fileToBase64(file);
        promise = chatService.sendMessage({
          type: 'file',
          data: {
            file: {
              name: file.name,
              type: file.type,
              data
            }
          }
        });
      } else {
        promise = chatService.sendMessage({ type: 'text', text });
      }
      
      setTimeout(async () => {
        scrollTo();
        await store.handleMessageResponse(promise);
        setTimeout(() => {
          scrollTo();
        });
      });
    });
  }
}

const onCreateChat = (id?: string) => {
  createChat(id);
}

const onAddInputText = (text: string) => {
  const inputElement = document.querySelector('form.sc-user-input > div') as HTMLElement;
  if (inputElement) {
    inputElement.focus();
    inputElement.textContent = text;
    const sel = document.getSelection();
    sel?.getRangeAt(0)['setStart'](inputElement, 1);
  }
}

const onSend = (text: string) => {
  onMessageSent({ text });
}

const listener = (event) => {
  store.clickEvent = event;
}

const onCartOpen = () => {
  createCart();
  state.isCartModalOpen = true
}

const onCheckout = () => {
  onSend('checkout');
}

const createCart = () => {
  const cartMessages = store.messages.filter((message) => message.chatName);
  state.carts = cartMessages.map((message) => {
    const items = message.data.props.items.map((item) => item.selectedItem);
    return {
      items,
      name: message.cartName || message.chatName || '',
      total: items.reduce((sum, item) => (sum += item.price, sum), 0)
    };
  });
  state.total = state.carts.reduce((sum, item) => (sum += item.total, sum), 0);
  state.total = Math.round(state.total * 100) / 100;
  state.count = state.carts.reduce((sum, cart) => (sum += cart.items.length, sum), 0);
}

onMounted(() => {
  window.addEventListener('click', listener);
});

onDeactivated(() => {
  window.removeEventListener('click', listener);
});
</script>

<template>
  <div class="fc-cartie">
    <Chat
      :messages="store.messages"
      :isOpen="store.isOpen"
      @onOpen="onOpen"
      @onSend="onMessageSent"
    >
      <template #header>
        <ChatHeader :cart-count="state.count" @onCreate="onCreateChat" @onClose="onClose" @onCart="onCartOpen" @onCheckout="onCheckout" />
      </template>

      <template #message="{ message }">
        <ChatMessage :message="message" @onAddText="onAddInputText" />
      </template>

      <template #footer="{ messages }">
        <div v-if="messages[messages.length - 1]?.data?.prompt">
          <Compile :template="messages[messages.length - 1].data.prompt" :props="messages[messages.length - 1].data.props" @select="onSend($event)"></Compile>
        </div>
      </template>
    </Chat>

    <Modal
      v-if="state.isCartModalOpen"
      teleport-to=".chat__messages-wrapper"
      class="fc-cartie__cart-modal"
      width="calc(100% - 40px)"
      height="calc(100% - 40px)"
      top="20px"
      left="20px"
    >
      <template #header>
        <div class="fc-cartie__cart-modal-header">
          <span>Cart</span>
          <div class="fc-cartie__cart-modal-close" @click="state.isCartModalOpen = false">
            <Icon name="x"></Icon>
          </div>
        </div>
      </template>
      
      <div class="fc-cartie__cart-modal-body">
        <div v-for="(cart, index) in state.carts" :key="index" class="fc-cartie__cart-modal-cart-wrapper">
          <div class="fc-cartie__cart-modal-cart">
            <div class="fc-cartie__cart-modal-cart-name">{{ cart.name }} ({{ cart.items.length }} items)</div>
            <div class="fc-cartie__cart-modal-cart-list">
              <CartItemComp v-for="(item, index) in cart.items"  :key="index" :item="item" class="fc-cartie__cart-modal-cart-item" />
            </div>
          </div>
        </div>
      </div>
      
      <template #footer>
      <div class="fc-cartie__cart-modal-footer">
        <div class="">
          <span>Subtotal ({{ state.count }} items):</span>
          <span class="fc-cartie__cart-modal-price-total">${{ state.total }}</span>
        </div>
        <Button primary class="fc-cartie__cart-modal-button" @click="onCheckout">
          <span>Checkout</span>
        </Button>
      </div>
    </template>
    </Modal>
  </div>
</template>

<style lang="scss">
  #app {
    position: absolute;
    z-index: 10;

    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;

    --main-color: #814CD6;
    --main-color-light: #814cd640;

    --text-color-button: #fff;

    --text-color: #333;

    --surface: #fff;

    --message-background: #F7F7F7;

    @media (prefers-color-scheme: dark) {
      // --main-color: #e15050;
      // --main-color-light: #e66896b1;
    }
  }
  #app div,
  #app span {
    box-sizing: border-box;
  }
</style>

<style scoped lang="scss">
@import '@styles/mixins.scss';

.fc-cartie {
  color: var(--text-color);

  &__modal-actions {
    button {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  &__cart-modal {
    top: 10px;

    &-close {
      cursor: pointer;
      background-color: #F7F7F7;
      padding: 4px;
      border-radius: 8px;
      margin-left: 4px;
      line-height: 16px;
      height: 24px;
    }

    &-header {
      @include flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 700;
      border-bottom: 1px solid #DFDFDF;
      padding: 16px;
    }

    &-body {
      height: calc(100% - 116px);
      overflow-y: auto;
    }

    &-footer {
      @include flex;
      border-top: 1px solid #DFDFDF;
      justify-content: flex-end;
      padding: 8px;
    }

    &-button {
      @include flex;
      justify-content: center;
      width: 100px;
    }

    &-cart {
      overflow-x: auto;
      width: 100%;
      background: var(--message-background);
      border-radius: 10px;
      padding: 18px 12px;

      &-name {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 12px;
      }

      &-wrapper {
        padding: 12px;
      }

      &-list {
        @include flex;
        width: 100%;
        height: 220px;
        overflow-x: auto;
      }

      &-item {
        min-width: 170px;
        display: block;

        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }

    &-price-total {
      font-size: 18px;
      font-weight: 700;
      margin: 0 12px;
    }
  }
}
</style>