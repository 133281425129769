<script setup lang="ts">

const props = defineProps<{
  teleportTo?: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  width?: string;
  height?: string;
}>();
const emit = defineEmits(['close']);

const app = document.querySelector(props.teleportTo || '#app');
</script>

<template>
  <Teleport :to="app">
    <div class="fc-modal__back" @click="emit('close')">
      <div class="fc-modal" :style="{ top: props.top, bottom: props.bottom, left: props.left, right: props.right, width: props.width, height: props.height }">
        <div class="fc-modal__header">
          <slot name="header"></slot>
        </div>
        <slot></slot>
        <div class="fc-modal__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
.fc-modal {
  position: absolute;
  width: 400px;
  border: 1px solid #ccc;
  box-shadow: 0 7px 40px 2px rgba(148, 149, 150, .3);
  z-index: 1;
  border-radius: 10px;
  background: #fff;

  -webkit-font-smoothing: subpixel-antialiased;

  &__back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 50%);
    z-index: 1;
  }
}
</style>
