import { createApp } from 'vue/dist/vue.esm-bundler';
import Icon from  './assets/icon.component.vue';
import App from './app/app.vue';

class AppBootstrap {
  constructor() {
    this.initApp();
  }

  initApp() {
    createApp(App)
      .component('Icon', Icon)
      .mount("#app")
  }
}

new AppBootstrap()