<script setup lang="ts">
import { Compile } from '@components/common';
import { Message } from '../../../models';

const { message } = defineProps<{message: Message}>();
const emit = defineEmits(['onAddText']);
</script>

<template>
  <div v-if="message.type === 'text' || message.type === 'html'" class="chat-message__message" :class="message.author === 'me' && 'chat-message__message-me'">
    <div v-if="message.author !== 'me'" class="chat-message__message-self">
      <b>Cartie</b>
      <span v-if="message.isLoading" class="chat-message__message-spinner"></span>
    </div>
    <div>
      <span>{{ message.data.text }}</span>
    </div>
    <div v-if="message.data.template">
      <Compile :template="message.data.template" :props="message.data.props" :messageId="message.id" @add="emit('onAddText', 'add ingredient ')"></Compile>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chat-message {
  &__message {
    background: var(--message-background);
    color: var(--text-color);
    padding: 12px 16px;
    border-radius: 16px;
    margin: 16px 0;

    &-self {
      display: flex;
      align-items: center;

      b {
        margin-right: 8px;
      }
    }

    &-me {
      max-width: 40%;
      display: flex;
      align-self: flex-end;
      background: var(--main-color-light);
    }

    &-spinner {
      width: 17px;
      height: 17px;
      border: 5px dotted #CCC;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      animation: rotation 2s linear infinite;
      margin-right: 12px;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>